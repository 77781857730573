import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { RequestBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/RequestBuilder'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource'
import PivotColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/PivotColumnsBuilder'
import { Nullable } from '@/core/domain/type/types'
import { IParameters } from '@/components/InterfaceEditor/components/utils'

export class DataWarehouseSource implements IServerSideDatasource {
  private readonly entityId: number
  private externalFilters: Nullable<object[]>
  private readonly type: string
  private requestTypes = {
    extended_object: APIDataSource.ExtendedObjectQuery,
    query: APIDataSource.QueryQuery
  }
  private lastPayload: object
  private initClass: boolean
  private context: any
  private queryParameters: IParameters

  constructor (
    entityId: number,
    type: string,
    externalFilters: Nullable<object[]> = null,
    queryParameters: IParameters,
    initClass: boolean = false,
    context: any
  ) {
    this.entityId = entityId
    this.externalFilters = externalFilters
    this.queryParameters = queryParameters
    this.type = type
    this.initClass = initClass
    this.context = context
  }

  setExternalFilters (externalFilters: Nullable<object[]>) {
    this.externalFilters = externalFilters
  }
  getExternalFilters (): object[] {
    return this.externalFilters || []
  }
  getLastPayload (): object {
    return this.lastPayload
  }
  getRows (params: IServerSideGetRowsParams) {
    const pivotColumnIsXref = params.request.pivotCols.length > 0
      ? params.columnApi.getColumn(params.request.pivotCols[0].id).getUserProvidedColDef().type.includes('xrefField')
      : false
    const payload = RequestBuilder.build(params.request, pivotColumnIsXref, this.externalFilters)

    if (this.type === 'query' && Object.keys(this.queryParameters).length !== 0) {
      payload.query_parameters = this.queryParameters
    }
    this.lastPayload = payload
    // Есть фильтры в таблице, не делать первую загрузку при иницилизации компонента, а дождаться фильтров
    if (this.initClass) {
      return
    }
    console.log('%c%s', 'color: pink;', 'AnalyticTable ЗАГРУЗКА', this.entityId)

    this.context.loading = true
    APIClient.shared.request(new this.requestTypes[this.type](this.entityId, payload))
      .then((response: {data, pivot_fields}) => {
        let data
        let pivotFields
        if (params.request.pivotMode && params.request.pivotCols.length > 0) {
          data = response.data
          pivotFields = response.pivot_fields
          if (params.request.groupKeys.length === 0) {
            PivotColumnsBuilder.build(params.request, pivotFields, params.columnApi)
          }
        } else {
          params.columnApi.setPivotResultColumns([])
          data = response
        }
        params.success({ rowData: data, rowCount: this.getLastRow(params.request, data.length) })
        this.context.loading = false
      })
      .catch((error) => {
        params.fail()
        this.context.loading = false
        throw error
      })
  }

  private getLastRow (request: IServerSideGetRowsRequest, resultLength: number) {
    if (resultLength === 0) {
      return request.startRow
    }

    const currentLastRow = request.startRow + resultLength + 1

    return currentLastRow <= request.endRow ? (currentLastRow - 1) : -1
  }

  getType (): string {
    return this.type
  }
  isFirstRequest (): boolean {
    return this.initClass
  }
  setIsFirstRequest (value:boolean) {
    this.initClass = value
  }
}
